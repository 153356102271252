<template>
  <div class="s-route">
    <div class="s-route__container v2-container">
      <div class="s-route__left">
        <h2 class="s-route__title title-secondary">
          Индивидуальный образовательный маршрут
        </h2>

        <p class="s-route__descr text-main">
          Мы регулярно проводим срез знаний ребенка,
          оцениваем прогресс и даем дополнительные материалы и рекомендации
        </p>

        <ul class="s-route__list">
          <li class="s-route__list-item">
            <button
              class="s-route__btn"
              :class="{'s-route__btn--soc' : subject === 'soc'}"
              @click="subject = 'soc'"
            >
              Социально-гуманитарные
            </button>
          </li>

          <li class="s-route__list-item">
            <button
              class="s-route__btn"
              :class="{'s-route__btn--exact' : subject === 'exact'}"
              @click="subject = 'exact'"
            >
              Точные
            </button>
          </li>

          <li class="s-route__list-item">
            <button
              class="s-route__btn"
              :class="{'s-route__btn--natural' : subject === 'natural'}"
              @click="subject = 'natural'"
            >
              Естественные
            </button>
          </li>

          <li class="s-route__list-item">
            <button
              class="s-route__btn"
              :class="{'s-route__btn--art' : subject === 'art'}"
              @click="subject = 'art'"
            >
              Творческие
            </button>
          </li>
        </ul>
      </div>

      <div class="s-route__rigth">
        <img
          loading="lazy"
          :src="require(`~/static/v2/school/educ-route/${subject}.svg`)"
          alt="диаграмма"
          class="s-route__img"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchoolEducRoute',

  data() {
    return {
      subject: 'soc',
    };
  },
};
</script>

<style lang="scss" scoped>
.s-route {
  overflow: hidden;

  &__container {
    display: flex;
    gap: 10px;

    max-width: 1272px;

    @media only screen and (max-width: $school-desktop) {
      flex-direction: column;
    }
  }

  &__left {
    flex-shrink: 0;

    padding-top: 150px;

    max-width: 505px;

    @media only screen and (max-width: $school-desktop) {
      margin: 0 auto;
    }

    @include media-down(tablet) {
      padding-top: 0;
    }
  }

  &__title {
    margin-bottom: 18px;

    letter-spacing: -1.35px;

    @media only screen and (max-width: $school-desktop) {
      text-align: center;
    }

    @include media-down(tablet) {
      margin-bottom: 12px;

      text-transform: initial;
    }
  }

  &__descr {
    margin-bottom: 40px;

    @media only screen and (max-width: $school-desktop) {
      text-align: center;
    }

    @include media-down(tablet) {
      margin-bottom: 32px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;

    max-width: 400px;

    @media only screen and (max-width: $school-desktop) {
      justify-content: center;

      margin: 0 auto;
    }
  }

  &__btn {
    padding: 9px 18px 11px;

    border-radius: 50px;
    border: 2px dashed $black;
    color: $black;

    background: transparent;

    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
    white-space: nowrap;

    flex-shrink: 0;
    display: block;
    transition-duration: 400ms;
    transition-timing-function: ease-out;

    cursor: pointer;

    @media (hover: hover) {
      &:hover:not(
          .s-route__btn--art,
          .s-route__btn--natural,
          .s-route__btn--soc,
          .s-route__btn--exact
        ) {
        border-style: solid;
      }
    }

    &--soc {
      background-color: $blue;
      border-style: solid;
    }
    &--exact {
      background-color: $orange;
      border-style: solid;
    }
    &--natural {
      background-color: $green;
      border-style: solid;
    }
    &--art {
      background-color: $violet;
      border-style: solid;
    }
  }

  &__rigth {
    position: relative;
    right: -80px;

    @media only screen and (max-width: $school-desktop) {
      position: static;

      display: flex;
      justify-content: center;
    }

    @include media-down(laptop) {
      margin: 0 -8px;
    }
  }

  &__img {
    @include media-down(tablet) {
      display: block;
      width: 100%;
      object-fit: contain;
      max-width: 500px;
    }
  }
}
</style>
